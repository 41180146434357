@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500);
body {
  display: block;
  margin: 0;
  padding: 0;
  color: white;
  background-image: linear-gradient(#2a7cc1, #4d95b7);
  background-repeat: no-repeat;
  font-family: 'Quicksand', sans-serif;
  min-height: 100vh;
}


button {
  font-size: 2em;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  padding: 6px 26px;
  -webkit-flex: none;
          flex: none;
  background: transparent;
  border: solid 2px white;
  border-radius: 1em;
  color: white;
  transition: background 150ms;
  cursor: pointer;
}

button:hover {
  background: #d9463e;
}

button:active {
  box-shadow: 0 0 0 4px rgba(0,0,0,.15) inset,0 0 6px rgba(0,0,0,.2) inset;
}

button:disabled {
  background-color: grey;
}

a {
  color: white;
}
.location-button {
    width: 100%;
}

.location-error {
    position: absolute;
    top: 0;
    padding: 8px;
    width: 300px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #ffd023;
    color: rgb(58, 58, 58);
    box-shadow: 3px 3px 6px 6px rgb(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s, visibility 0.4s;
}

.location-error_displayed {
    visibility: visible;
    opacity: 1;
}

.location-error__text {
    width: 260px;
    font-size: 14px;
}

.location-error__exit-btn {
    font-size:1.2em;
    font-weight: 500;
    padding: 6px 10px;
    border: none;
    color: rgb(175, 175, 175);
    position: absolute;
    right: 4px;
    top: 50%;
    -webkit-transform: translateY(-53%);
            transform: translateY(-53%);
    transition: color 0.2s;
}

.location-error__exit-btn:hover {
    background: none;
    color: rgb(121, 121, 121);
}
.search-bar-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.search-bar__input-and-button {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.search-bar__input {
    font-size: 2em;
    font-family: 'Quicksand';
    font-weight: 400;
    padding: 8px 16px;
    text-align: left;
    width:450px;
    border-radius: 1em 0 0 1em;
    border-radius: 1em;
    border: none;
    margin-right: 8px;
}

.search-bar__button {
    border-radius: 0 1em 1em 0;
    border-radius: 1em;
    padding:0;
    height: 56px;
    width: 56px;
}

.search-bar__button-icon {
    height:1.1em;
    padding-top:15%;
}

.pac-container {
    font-family: 'Quicksand';
    letter-spacing: 0.4px;
}

.pac-container {
    font-weight: 400;
    margin-top: 6px;
}

.pac-item-query, .pac-item {
    font-size: 14px;
}

.pac-matched {
    font-weight: 500;
}

.fade-enter {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  
  .fade-enter-done {
    opacity: 1;
    transition: all 0.5s ease-out;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
  }
.footer {
  position: absolute;
  bottom: 4px;
  text-align: center;
  font-size: 13px;
  left: 0;
  right: 0;
}

.footer__line {
  margin: 3px;
}

.footer__button {
  font-size: 1em;
  padding: 2px 10px;
  border-width: 1px;
}

.footer__link {
  color: white;
}

.footer__attribution {
  margin: 2px;
}

.footer__attribution > a {
  color: white;
}

@media (max-width: 1115px), (max-height: 750px) {
  .footer {
    position: relative;
  }

  .footer--splash {
    margin-top: 30px;
  }
}

@media (max-height: 800px) and (min-width: 984px) {
  .footer--main-page {
    font-size: 12px;
  }
}

.splash {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.or {
  margin: 20px 0;
  font-size: 3em;
  font-weight: 300;
}

@media (max-width: 680px) {
  .splash {
    font-size: 12px;
  }

  .splash .search-bar__button {
    height: 46px;
    width: 46px;
  }
}

@media (max-width: 585px) {
  .splash .search-bar-container,
  .splash .location-button-container {
    width: 90%;
  }

  .splash .search-bar {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .splash .search-bar__input-and-button {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .splash .search-bar__input {
    margin-bottom: 12px;
    width: 90%;
  }
}

.current-weather {
    margin-top: -10px;
    margin-bottom: 40px; 
    text-align: center;
}

.place-name {
    text-align: center;
    font-size: 1.8em;
    font-weight: 300;
    margin-bottom: 28px;
  }

.current-weather__icon-temp-container {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.current-weather__icon {
    width: 160px;
}

.current-weather__temp {
    font-size: 7em;
    font-weight: 400;
    margin-right: 20px;
}

.current-weather__description {
    font-size: 4em;
    font-weight: 300;
}

@media (max-width: 1115px), (max-height: 750px) {
    .current-weather {
        margin-top: 0;
        margin-bottom: 26px;
        font-size: 15px;
        margin: 0 6px 26px 6px;
    }

    .place-name {
        margin-top: 40px;
        margin-bottom: 0;
      }
}

@media (max-width: 645px) {
    .current-weather {
        font-size: 13px;
    }
}

@media (max-width: 380px) {
    .current-weather {
        font-size: 12px;
    }
}

@media (max-height: 794px) and (min-width: 984px) {
    .current-weather {
        font-size: 13px;
    }

    .place-name {
        margin-bottom: 6px;
    }
}
.forecast {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.forecast-day {
  text-align: center;
  -webkit-flex: auto;
          flex: auto;
  padding: 12px 4px;
  max-width: 190px;
}

.forecast-day--light {
  background-color: rgba(255, 255, 255, 0.06);
}

.forecast-day__weekday {
  font-size: 1.5em;
}

.forecast-day__date {
  font-weight: 300;
}

.forecast-day__main-icon {
  margin-top: 8px;
}

.forecast-day__description {
  width: 150px;
  margin: auto;
  font-size: 0.85em;
  font-weight: 300;
  letter-spacing: 0.6px;
  height: 35px;
}

.forecast-day__description_small {
  font-size: 0.7em;
}

.forecast-day__details {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 6px;
  margin-bottom: 4px;
}

.forecast-detail {
  margin: 0 6px;
  font-size: 0.9em;
}

.forecast-day__wind-speed-units {
  font-size: 0.7em;
}

.forecast-detail__icon {
  width: 38px;
  margin-bottom: -3px;
}

@media (min-width: 984px) and (max-width: 1470px) {
  .forecast-day {
    width: 10%;
  }

  .forecast-day__details {
    width: 100px;
    display: block;
    margin: 2px auto 4px auto;
  }

  .forecast-detail {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .forecast-day__description {
    width: 90%;
    font-size: 0.8em;
    letter-spacing: 0.2px;
    height: 50px;
  }

  .forecast-day__description_small {
    font-size: 0.65em;
  }
}

@media (max-width: 1115px), (max-height: 750px) {
  .forecast {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 10px;
  }

  .forecast-day {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;
    height: 96px;
    text-align: left;
    max-width: unset;
    width: auto;
  }

  .forecast-day__weekday-date {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 3%;
  }

  .forecast-day__weekday {
    font-size: 1.7em;
    font-weight: 300;
  }

  .forecast-day__date {
    font-size: 1.2em;
    font-weight: 200;
  }

  .forecast-day__main-icon {
    margin-top: 0;
    margin-right: 12px;
    height: 90px;
  }

  .forecast-day__description {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: auto;
    font-size: 1.05em;
  }

  .forecast-day__details {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-top: 0;
    margin-right: 3%;
    margin-left: 2%;
    width: auto;
    display: -webkit-flex;
    display: flex;
  }

  .forecast-detail {
    margin: 0;
    text-align: center;
    font-size: 1.2em;
    font-weight: 200;
    -webkit-flex: 1 1;
            flex: 1 1;
    display: block;
  }

  .forecast-detail__icon {
    width: 48px;
    margin-bottom: -3px;
  }
}

@media (max-width: 855px) {
  .forecast-day__weekday-date {
    -webkit-flex: 0.7 1;
            flex: 0.7 1;
  }

  .forecast-day__weekday {
    font-size: 1.5em;
  }

  .forecast-day__date {
    font-size: 1em;
  }

  .forecast-day__description {
    -webkit-flex: 0.7 1;
            flex: 0.7 1;
    font-size: 0.95em;
  }

  .forecast-detail {
    font-size: 1em;
  }
}

@media (max-width: 548px) {
  .forecast-day__weekday-date {
    -webkit-flex: 0.7 1;
            flex: 0.7 1;
  }

  .forecast-day__description {
    display: none;
  }

  .forecast-day__main-icon {
    height: 100px;
    -webkit-flex: 0.5 1;
            flex: 0.5 1;
  }

  .forecast-detail {
    font-size: 0.9em;
  }
}

@media (max-width: 390px) {
  .forecast-day {
    height: 78px;
  }

  .forecast-day__weekday {
    font-size: 1.3em;
    font-weight: 300;
  }

  .forecast-day__date {
    font-size: 0.8em;
    font-weight: 200;
  }

  .forecast-day__main-icon {
    height: 78px;
  }

  .forecast-detail {
    width: 48px;
    font-size: 0.8em;
  }

  .forecast-detail__icon {
    width: 32px;
  }
}

@media (max-width: 340px) {
  .forecast-day {
    height: 72px;
  }

  .forecast-day__weekday {
    font-size: 1.1em;
    font-weight: 300;
  }

  .forecast-day__date {
    font-size: 0.8em;
    font-weight: 200;
  }

  .forecast-day__main-icon {
    height: 68px;
  }

  .forecast-detail {
    width: 48px;
  }

  .forecast-detail__icon {
    width: 28px;
  }
}

.header {
    display: -webkit-flex;
    display: flex;
    font-size: 7px;
    position: absolute;
    top: 16px;
    left: 16px;
    -webkit-align-items: center;
            align-items: center;
}

.header .or {
    margin: 0 16px;
}

.header button {
    border-width: 1px;
}

.header .location-button {
    padding: 3px 23px;
    height: 28px;
}

.header .search-bar__input {
    padding: 5px 13px;
    width: 240px;
}

.header .search-bar__button {
    height: 28px;
    width: 28px;
}

@media (max-width: 1115px), (max-height: 750px) {
    .header {
        left: 0;
        right: 0;
        margin: auto;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        width: 95%;
    }

    .header .search-bar-container {
        -webkit-flex: 2 1;
                flex: 2 1;
    }

    .header .location-button-container {
        -webkit-flex: 1 1;
                flex: 1 1;
    }

    .header .or {
        -webkit-flex: 0.2 1;
                flex: 0.2 1;
        text-align: center;
    }

    .header .search-bar {
        width:  100%;
    }

    .header .search-bar__input {
        width:  90%;
    }
}

@media (max-width: 645px) {
    .header {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap
    }

    .header .search-bar-container {
        -webkit-flex: none;
                flex: none;
        width: 100%;
        margin-top: 10px;
    }

    .header .location-button-container {
        -webkit-flex: none;
                flex: none;
    }

    .header .or {
        -webkit-flex: none;
                flex: none;
        text-align: center;
    }
}
.weather-report {
  margin: 0 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100vh;
}

@media (max-width: 1115px), (max-height: 750px) {
  .weather-report {
    margin: 0;
    margin-top: 30px;
    height: auto;
  }
}

@media (max-width: 645px) {
  .weather-report {
    margin-top: 80px;
  }
}

