.forecast {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.forecast-day {
  text-align: center;
  flex: auto;
  padding: 12px 4px;
  max-width: 190px;
}

.forecast-day--light {
  background-color: rgba(255, 255, 255, 0.06);
}

.forecast-day__weekday {
  font-size: 1.5em;
}

.forecast-day__date {
  font-weight: 300;
}

.forecast-day__main-icon {
  margin-top: 8px;
}

.forecast-day__description {
  width: 150px;
  margin: auto;
  font-size: 0.85em;
  font-weight: 300;
  letter-spacing: 0.6px;
  height: 35px;
}

.forecast-day__description_small {
  font-size: 0.7em;
}

.forecast-day__details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 4px;
}

.forecast-detail {
  margin: 0 6px;
  font-size: 0.9em;
}

.forecast-day__wind-speed-units {
  font-size: 0.7em;
}

.forecast-detail__icon {
  width: 38px;
  margin-bottom: -3px;
}

@media (min-width: 984px) and (max-width: 1470px) {
  .forecast-day {
    width: 10%;
  }

  .forecast-day__details {
    width: 100px;
    display: block;
    margin: 2px auto 4px auto;
  }

  .forecast-detail {
    display: flex;
    align-items: center;
  }

  .forecast-day__description {
    width: 90%;
    font-size: 0.8em;
    letter-spacing: 0.2px;
    height: 50px;
  }

  .forecast-day__description_small {
    font-size: 0.65em;
  }
}

@media (max-width: 1115px), (max-height: 750px) {
  .forecast {
    flex-direction: column;
    padding-bottom: 10px;
  }

  .forecast-day {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 96px;
    text-align: left;
    max-width: unset;
    width: auto;
  }

  .forecast-day__weekday-date {
    flex: 1;
    margin-left: 3%;
  }

  .forecast-day__weekday {
    font-size: 1.7em;
    font-weight: 300;
  }

  .forecast-day__date {
    font-size: 1.2em;
    font-weight: 200;
  }

  .forecast-day__main-icon {
    margin-top: 0;
    margin-right: 12px;
    height: 90px;
  }

  .forecast-day__description {
    flex: 1;
    height: auto;
    font-size: 1.05em;
  }

  .forecast-day__details {
    flex: 1;
    margin-top: 0;
    margin-right: 3%;
    margin-left: 2%;
    width: auto;
    display: flex;
  }

  .forecast-detail {
    margin: 0;
    text-align: center;
    font-size: 1.2em;
    font-weight: 200;
    flex: 1;
    display: block;
  }

  .forecast-detail__icon {
    width: 48px;
    margin-bottom: -3px;
  }
}

@media (max-width: 855px) {
  .forecast-day__weekday-date {
    flex: 0.7;
  }

  .forecast-day__weekday {
    font-size: 1.5em;
  }

  .forecast-day__date {
    font-size: 1em;
  }

  .forecast-day__description {
    flex: 0.7;
    font-size: 0.95em;
  }

  .forecast-detail {
    font-size: 1em;
  }
}

@media (max-width: 548px) {
  .forecast-day__weekday-date {
    flex: 0.7;
  }

  .forecast-day__description {
    display: none;
  }

  .forecast-day__main-icon {
    height: 100px;
    flex: 0.5;
  }

  .forecast-detail {
    font-size: 0.9em;
  }
}

@media (max-width: 390px) {
  .forecast-day {
    height: 78px;
  }

  .forecast-day__weekday {
    font-size: 1.3em;
    font-weight: 300;
  }

  .forecast-day__date {
    font-size: 0.8em;
    font-weight: 200;
  }

  .forecast-day__main-icon {
    height: 78px;
  }

  .forecast-detail {
    width: 48px;
    font-size: 0.8em;
  }

  .forecast-detail__icon {
    width: 32px;
  }
}

@media (max-width: 340px) {
  .forecast-day {
    height: 72px;
  }

  .forecast-day__weekday {
    font-size: 1.1em;
    font-weight: 300;
  }

  .forecast-day__date {
    font-size: 0.8em;
    font-weight: 200;
  }

  .forecast-day__main-icon {
    height: 68px;
  }

  .forecast-detail {
    width: 48px;
  }

  .forecast-detail__icon {
    width: 28px;
  }
}
