.search-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.search-bar__input-and-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-bar__input {
    font-size: 2em;
    font-family: 'Quicksand';
    font-weight: 400;
    padding: 8px 16px;
    text-align: left;
    width:450px;
    border-radius: 1em 0 0 1em;
    border-radius: 1em;
    border: none;
    margin-right: 8px;
}

.search-bar__button {
    border-radius: 0 1em 1em 0;
    border-radius: 1em;
    padding:0;
    height: 56px;
    width: 56px;
}

.search-bar__button-icon {
    height:1.1em;
    padding-top:15%;
}

.pac-container {
    font-family: 'Quicksand';
    letter-spacing: 0.4px;
}

.pac-container {
    font-weight: 400;
    margin-top: 6px;
}

.pac-item-query, .pac-item {
    font-size: 14px;
}

.pac-matched {
    font-weight: 500;
}
