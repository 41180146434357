.current-weather {
    margin-top: -10px;
    margin-bottom: 40px; 
    text-align: center;
}

.place-name {
    text-align: center;
    font-size: 1.8em;
    font-weight: 300;
    margin-bottom: 28px;
  }

.current-weather__icon-temp-container {
    display:flex;
    justify-content: center;
    align-items: center;
}

.current-weather__icon {
    width: 160px;
}

.current-weather__temp {
    font-size: 7em;
    font-weight: 400;
    margin-right: 20px;
}

.current-weather__description {
    font-size: 4em;
    font-weight: 300;
}

@media (max-width: 1115px), (max-height: 750px) {
    .current-weather {
        margin-top: 0;
        margin-bottom: 26px;
        font-size: 15px;
        margin: 0 6px 26px 6px;
    }

    .place-name {
        margin-top: 40px;
        margin-bottom: 0;
      }
}

@media (max-width: 645px) {
    .current-weather {
        font-size: 13px;
    }
}

@media (max-width: 380px) {
    .current-weather {
        font-size: 12px;
    }
}

@media (max-height: 794px) and (min-width: 984px) {
    .current-weather {
        font-size: 13px;
    }

    .place-name {
        margin-bottom: 6px;
    }
}