.weather-report {
  margin: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

@media (max-width: 1115px), (max-height: 750px) {
  .weather-report {
    margin: 0;
    margin-top: 30px;
    height: auto;
  }
}

@media (max-width: 645px) {
  .weather-report {
    margin-top: 80px;
  }
}
