@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500');

body {
  display: block;
  margin: 0;
  padding: 0;
  color: white;
  background-image: linear-gradient(#2a7cc1, #4d95b7);
  background-repeat: no-repeat;
  font-family: 'Quicksand', sans-serif;
  min-height: 100vh;
}


button {
  font-size: 2em;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  padding: 6px 26px;
  flex: none;
  background: transparent;
  border: solid 2px white;
  border-radius: 1em;
  color: white;
  transition: background 150ms;
  cursor: pointer;
}

button:hover {
  background: #d9463e;
}

button:active {
  box-shadow: 0 0 0 4px rgba(0,0,0,.15) inset,0 0 6px rgba(0,0,0,.2) inset;
}

button:disabled {
  background-color: grey;
}

a {
  color: white;
}